'use strict';

$(document).ready(function () {
    $('.detail-slider-nav').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        focusOnSelect: true,
        infinite: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 544,
                settings: {
                    arrows: false
                }
            }
        ]
    });
});
